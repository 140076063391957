import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import PostLink from '../components/PostLink'
import Me from '../../assets/me.png'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />)
    .map((post) => <li key={post.key}>{post}</li>)

  return (
    <div>
      <Helmet>
        <title>Hey, I'm Sanj</title>
      </Helmet>
      <h2>Hey, I'm Sanj Ambalavanar</h2>
      <div className="intro">
        <div className="text">
          <p>
            I'm a software engineer currently working at{' '}
            <a href="https://volteras.com">Volteras</a>, previously worked at{' '}
            <a href="https://freetrade.io">Freetrade</a>, and built{' '}
            <a href="https://suggested.co">Suggested</a>.
          </p>
        </div>
        <img src={Me} className="me" alt="me" />
      </div>
      <h3>Recent posts:</h3>
      <ul className="post-list">{Posts}</ul>

      <h3>Find me on the internet:</h3>
      <p>
        You can find me on <a href="https://github.com/sanjeevan">github</a>,{' '}
        <a href="https://twitter.com/sanjeevan84">twitter</a>,{' '}
        <a href="https://www.linkedin.com/in/sanjeevanambalavanar/">linkedin</a>{' '}
        or <a href="mailto:hello@sanjeevan.co.uk">email</a>
      </p>

      <h3>Open source projects:</h3>
      <ul className="oss-list">
        <li>
          <a href="https://github.com/sanjeevan/baseimage">baseimage</a> - A
          base docker image for running processes using runit and alpine linux
        </li>
        <li>
          <a href="https://github.com/sanjeevan/flask-json-schema">
            Flask-json-schema
          </a>{' '}
          - A Flask extension to validate JSON post data for your API endpoints
        </li>
      </ul>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
