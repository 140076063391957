import React from 'react'
import Link from 'gatsby-link'

const PostLink = ({ post }) => (
  <div>
    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
    <span style={{ color: '#aaa', fontSize: '0.8rem', marginLeft: '1rem' }}>
      ({post.frontmatter.date})
    </span>
  </div>
)

export default PostLink
